import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { CustomValidators } from "../../../../../../validator/custom-validators";

import { ChannelService } from "@service/channel/channel.service";
import { AreaService } from "@service/area/area.service";
import { CategoryService } from "@service/category/category.service";
import { AutocompleteService } from "@service/utilities/autocomplete.service";
import { PurchaseProgramService } from "@service/purchase-program/purchase-program.service";
import { ProgramService } from "@service/program/program.service";
import { PurchaseService } from "@service/purchase/purchase.service";
import { OfferProgramService } from "@service/offer-program/offer-program.service";
import { CustomToastrService } from "@service/toastr/custom-toastr.service";

import { PurchaseType } from "../../../../../../resource/purchaseType.resource";
import { FilteredItem } from "../../../../../../resource/filteredItem.resource";
import { Purchase } from "../../../../../../resource/purchase.resource";
import { PurchaseProgram } from "../../../../../../resource/purchaseProgram.resource";
import { Channel } from "src/app/resource/channel.resource";

import { Observable, of, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
  tap,
} from "rxjs/operators";

import * as moment from "moment";

@Component({
  selector: "app-program",
  templateUrl: "./program.component.html",
  styleUrls: ["./program.component.scss"],
})
export class ProgramComponent implements OnInit, OnChanges, OnDestroy {
  @Input() purchaseProgram: PurchaseProgram;
  @Input() purchase: Purchase;
  @Output() selectedPurchaseProgram = new EventEmitter<PurchaseProgram>();

  public programGroup: FormGroup;

  public loadingProgram: boolean = false;
  public savingPurchaseProgram: boolean = false;
  public loading: boolean = false;
  public isValid: boolean;
  public isOptionableValid: boolean;
  public isPurchaseValid: boolean = false;

  private channelItems: FilteredItem[];
  private areaItems: FilteredItem[];
  private categoryItems: FilteredItem[];
  private programItems: FilteredItem[];
  public purchaseProgramList: PurchaseProgram[];

  public filteredChannelItem: Observable<FilteredItem[]>;
  public filteredAreaItem: Observable<FilteredItem[]>;
  public filteredCategoryItem: Observable<FilteredItem[]>;
  public filteredProgramItem: Observable<FilteredItem[]>;
  public filteredOfferItem: Observable<FilteredItem[]>;

  public hintOptionable: boolean = false;
  public changes: any;
  public offerProgramEvt = [];

  private componentDestroyed: Subject<any> = new Subject();
  private destroyList$: Subject<boolean> = new Subject<boolean>();

  /**
   * Hint not alterable program
   *
   * @type {boolean}
   * @memberOf ProgramComponent
   */
  public hintNotAlterable: boolean = false;

  constructor(
    private fb: FormBuilder,
    private autocompleteService: AutocompleteService,
    private channelService: ChannelService,
    private areaService: AreaService,
    private purchaseProgramService: PurchaseProgramService,
    private offerProgramService: OfferProgramService,
    private categoryService: CategoryService,
    private programService: ProgramService,
    private purchaseService: PurchaseService,
    private customToastrService: CustomToastrService
  ) {}

  ngOnInit() {
    this.initForm();
    this.getDatas();
    this.setValueChanges();
    this.purchaseService.resetOffer();

    this.purchaseService.isOptionableSource$.subscribe((isOptionable) => {
      this.isOptionableValid = isOptionable;
    });

    this.purchaseService.isValidSource$.subscribe((valid) => {
      this.isPurchaseValid = valid;
    });
  }

  ngOnChanges(changes: any): void {
    this.purchaseProgramService
      .getList({ purchase_id: this.purchase.id, groups: "purchaseProgramLoad" })
      .pipe(takeUntil(this.destroyList$))
      .subscribe((data) => {
        this.purchaseProgramList = data;
      });

    this.changes = changes;

    if (changes.purchaseProgram) {
      this.purchaseProgram = changes.purchaseProgram.currentValue;
      this.getOfferProgramEvt(this.purchaseProgram);
      this.isOptionableValid = false;
      this.initForm();
      this.setValueChanges();
      this.getOffersFromOfferProgram();
    }
    if (changes.purchase) {
      this.purchase = changes.purchase.currentValue;
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.destroyList$.next();
    this.destroyList$.unsubscribe();
  }

  /**
   * Get events related to offer program
   * @param purchaseProgram
   */
  public getOfferProgramEvt(purchaseProgram) {
    const offerProgramParam = {
      offer_program_id: purchaseProgram.offerProgram.id,
    };
    this.offerProgramService
      .getOfferProgramEvents(offerProgramParam)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((data: []) => {
        if (data) {
          this.offerProgramEvt = data;
        }
      });
  }

  /**
   * Check if the program is already here
   * @returns false if detect same program
   */
  public checkExisting(): boolean {
    if (
      this.purchaseProgramList.find((item) => {
        return (
          this.programGroup.controls["channel"].value.value ===
            item.area.channel.id &&
          this.programGroup.controls["area"].value.value === item.area.id &&
          this.programGroup.controls["category"].value.value ===
            item.program.category.id &&
          this.programGroup.controls["offer"].value.value ===
            item.offerProgram.id &&
          this.programGroup.controls["program"].value.value === item.program.id
        );
      })
    ) {
      this.customToastrService.displayToastr(
        "WARNING",
        "Emission déja existante"
      );
      return false;
    } else {
      return true;
    }
  }

  public savePurchaseProgram() {
    if (this.programGroup.valid && this.checkExisting()) {
      this.savingPurchaseProgram = true;
      this.loading = true;

      this.purchaseProgramService
        .postPurchaseProgram(this.buildTemporaryPurchaseProgramToPost())
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(
          (purchaseProgram) => {
            this.purchaseProgram = purchaseProgram;
            this.selectedPurchaseProgram.emit(purchaseProgram);
            this.savingPurchaseProgram = false;
            this.loading = false;
            this.purchaseService.publishPurchaseProgramAdded(purchaseProgram);
            this.purchaseService.checkIfPurchaseIsOptionableByPurchaseProgram(
              purchaseProgram
            );
          },
          (error) => {
            this.loading = false;
            this.savingPurchaseProgram = false;
            this.resetPurchaseProgramFormGroup();
            this.customToastrService.displayToastr("ERROR", error.detail);
          }
        );
    }
  }

  public getOffersFromOfferProgram(): void {
    const controls = this.programGroup["controls"];

    if (
      !controls["program"].valid ||
      !controls["channel"].valid ||
      !controls["category"].valid
    ) {
      return;
    }

    let filter = {
      program_id: this.programGroup.controls["program"].value.value,
      channel_id: this.programGroup.controls["channel"].value.value,
      broadcasted_year: moment(
        this.purchase.startCommunicationPeriod,
        "YYYY-MM-DDTHH:mm:ss"
      ).format("YYYY"),
      area_id: this.programGroup.controls["area"].value.value,
    };

    if (
      this.purchase &&
      this.purchase.soreachPurchase &&
      controls["channel"] &&
      controls["channel"].value &&
      controls["channel"].value.entity &&
      controls["channel"].value.entity.channelGroup &&
      controls["channel"].value.entity.channelGroup !==
        Channel.THEMA_CHANNEL_GROUP
    ) {
      filter["soreach"] = this.purchase.soreachPurchase;
    }

    filter["program_disable"] = 1;

    this.loading = true;
    this.filteredOfferItem = of([]);

    this.offerProgramService
      .getList(filter)
      .pipe(
        startWith(null),
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((offerPrograms) => {
        if (offerPrograms && offerPrograms.length) {
          this.filteredOfferItem = of(
            offerPrograms.map(
              (offerProgram) =>
                new FilteredItem({
                  id: offerProgram.id,
                  name: offerProgram.offer.name,
                  entity: offerProgram,
                })
            )
          );
          console.log("this.filteredOfferItem", this.filteredOfferItem);
        } else {
          this.filteredOfferItem = of([]);
        }

        this.loading = false;
      });
  }

  public getPeriodFromOfferProgram(offerProgram): string {
    let startDate = moment(
      offerProgram.entity.entity.offer.periodStartDate,
      "YYYY-MM-DD"
    ).format("L");
    let endDate = moment(
      offerProgram.entity.entity.offer.periodEndDate,
      "YYYY-MM-DD"
    ).format("L");

    return `du ${startDate} au ${endDate}`;
  }

  private getDatas() {
    this.channelService.getListForFilter().subscribe((channelItems) => {
      this.channelItems = channelItems;
    });

    this.areaService.getListForFilter().subscribe((areaItems) => {
      if (this.changes && this.changes.purchaseProgram && areaItems) {
        this.programGroup.controls["area"].setValue(
          areaItems.find((data) => data.value === "NAT")
        );
      }
      this.areaItems = areaItems;
    });

    this.categoryService.getListForFilter().subscribe((categoryItems) => {
      this.categoryItems = categoryItems;
    });
    this.getOffersFromOfferProgram();
  }

  private setValueChanges() {
    const controls = this.programGroup["controls"];

    // Channel
    this.filteredChannelItem = controls["channel"].valueChanges.pipe(
      startWith(null),
      map((value) =>
        value
          ? this.autocompleteService.filterItems(value, this.channelItems)
          : this.channelItems
      ),
      tap(() => {
        if (this.purchaseProgram && this.purchaseProgram.id < 0) {
          this.filteredProgramItem = of([]);
          this.filteredOfferItem = of([]);
          this.programGroup.controls["category"].setValue("");
          this.programGroup.controls["program"].setValue("");
          this.programGroup.controls["offer"].setValue("");
          if (this.areaItems) {
            this.programGroup.controls["area"].setValue(
              this.areaItems.find((data) => data.value === "NAT")
            );
          }
        }
      })
    );

    // Area
    this.filteredAreaItem = controls["area"].valueChanges.pipe(
      startWith(null),
      map((value) =>
        value
          ? this.autocompleteService.filterItems(value, this.areaItems)
          : this.areaItems
      ),
      tap(() => {
        if (this.purchaseProgram && this.purchaseProgram.id < 0) {
          this.filteredProgramItem = of([]);
          this.filteredOfferItem = of([]);
          this.programGroup.controls["category"].setValue("");
          this.programGroup.controls["program"].setValue("");
          this.programGroup.controls["offer"].setValue("");
        }
      })
    );

    // Category
    this.filteredCategoryItem = controls["category"].valueChanges.pipe(
      startWith(null),
      map((value) =>
        value
          ? this.autocompleteService.filterItems(value, this.categoryItems)
          : this.categoryItems
      )
    );

    // Program
    controls["program"].valueChanges
      .pipe(startWith(null), debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (!value) {
          return;
        }

        this.loading = true;

        let filter = {
          broadcasted_year: moment(
            this.purchase.startCommunicationPeriod,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("YYYY"),
        };

        if (controls["channel"].valid) {
          filter["channel_id"] = controls["channel"].value.value;
        }

        if (controls["area"].valid) {
          filter["area_id"] = controls["area"].value.value;
        }

        if (
          this.purchase &&
          this.purchase.soreachPurchase &&
          controls["channel"] &&
          controls["channel"].value &&
          controls["channel"].value.entity &&
          controls["channel"].value.entity.channelGroup &&
          controls["channel"].value.entity.channelGroup !==
            Channel.THEMA_CHANNEL_GROUP
        ) {
          filter["soreach"] = this.purchase.soreachPurchase;
        }

        filter["program_disable"] = 1;
        filter["name"] = value;

        this.programService
          .getListForFilter(filter)
          .pipe(takeUntil(this.componentDestroyed))
          .subscribe(
            (programsItems) => {
              if (programsItems && programsItems.length > 0) {
                this.programItems = programsItems;
                this.filteredProgramItem = of(
                  this.autocompleteService.filterItems(value, this.programItems)
                );
              } else {
                this.filteredProgramItem = of([]);
              }

              this.loading = false;
            },
            () => {
              this.customToastrService.displayToastr(
                "ERROR",
                "Une erreur est survenue."
              );
            }
          );
      });

    // Offer
    controls["offer"].valueChanges.pipe(startWith(null)).subscribe((value) => {
      if (this.programGroup["controls"]["offer"].valid && value) {
        this.hintOptionable = !value.entity.entity.offer.isOptionable();

        if (this.purchase.type.id === PurchaseType.OPTION) {
          this.isOptionableValid = false;
        }
      }
    });

    // Channel
    controls["channel"].valueChanges.subscribe((value) => {
      if (value.value === "3") {
        controls["area"].enable();
      } else {
        controls["area"].disable();
      }
    });

    // Form
    this.programGroup.valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.getOffersFromOfferProgram();
      });
  }

  private initForm(): void {
    this.programGroup = this.fb.group({
      channel: ["", [CustomValidators.isFilteredItem, Validators.required]],
      area: ["", [CustomValidators.isFilteredItem]],
      category: ["", [CustomValidators.isFilteredItem, Validators.required]],
      program: ["", [CustomValidators.isFilteredItem, Validators.required]],
      offer: ["", [Validators.required, CustomValidators.isFilteredItem]],
      evt: [""],
    });

    this.populateForm();
  }

  private populateForm() {
    // NEW AND RESET
    if (this.purchaseProgram && this.purchaseProgram.id < 0) {
      if (this.areaItems) {
        this.programGroup.controls["area"].setValue(
          this.areaItems.find((data) => data.value === "NAT")
        );
      }
      this.programGroup.controls["area"].disable();
      this.filteredProgramItem = of([]);
      this.filteredOfferItem = of([]);
      return;
    }

    // SET
    const controls = this.programGroup["controls"];
    controls["channel"].setValue(
      new FilteredItem(this.purchaseProgram.area.channel)
    );
    controls["area"].setValue(new FilteredItem(this.purchaseProgram.area));
    controls["category"].setValue(
      new FilteredItem(this.purchaseProgram.program.category)
    );
    controls["program"].setValue(
      new FilteredItem(this.purchaseProgram.program)
    );
    controls["offer"].setValue(
      new FilteredItem({
        id: this.purchaseProgram.offerProgram.offer.id,
        name: this.purchaseProgram.offerProgram.offer.name,
      })
    );
    this.programGroup.controls["channel"].disable();
    this.programGroup.controls["area"].disable();
    this.programGroup.controls["category"].disable();
    this.programGroup.controls["program"].disable();
    this.programGroup.controls["offer"].disable();

    this.hintOptionable =
      !this.purchaseProgram.offerProgram.offer.isOptionable();
  }

  /**
   * Manage status when the user select a new program
   *
   * @private
   * @param
   * @memberOf ProgramComponent
   */
  private selectNewProgram(): void {
    let program = this.programGroup.controls["program"].value;
    this.hintNotAlterable = false;

    if (!this.purchase && !this.purchase.id) {
      return;
    }

    if (
      this.purchase.type.id !== PurchaseType.PURCHASE ||
      this.purchaseProgram.id < 0
    ) {
      // New purchase program
      const controls = this.programGroup["controls"];
      controls["category"].setValue(new FilteredItem(program.entity.category));
    } else {
      this.hintNotAlterable = true;
    }
  }

  /**
   * Build a temporary PurchaseProgram object to post
   *
   * @returns {Object}
   * @memberOf ProgramComponent
   */
  private buildTemporaryPurchaseProgramToPost(): Object {
    const controls = this.programGroup["controls"];
    const tempPurchaseProgram = new Object();

    tempPurchaseProgram["purchase"] = this.purchase;
    tempPurchaseProgram["offerProgram"] = controls["offer"].value.entity;
    tempPurchaseProgram["program"] = controls["program"].value.entity;
    tempPurchaseProgram["area"] = controls["area"].value.entity;
    tempPurchaseProgram["area"]["channel"] = controls["channel"].value.entity;
    tempPurchaseProgram["channel"] = controls["channel"].value.entity;

    return tempPurchaseProgram;
  }

  /**
   * Reset PurchaseProgram Form Group and reset filteredProgramItem and filteredOfferItem observables
   *
   * @returns {void}
   * @memberOf ProgramComponent
   */
  private resetPurchaseProgramFormGroup(): void {
    this.programGroup.get("area").setValue("");
    if (this.areaItems) {
      this.programGroup
        .get("area")
        .setValue(this.areaItems.find((data) => data.value === "NAT"));
    }
    this.programGroup.get("channel").setValue("");
    this.programGroup.get("category").setValue("");
    this.programGroup.get("program").setValue("");
    this.programGroup.get("offer").setValue("");

    this.programGroup.controls["area"].disable();

    this.filteredProgramItem = of([]);
    this.filteredOfferItem = of([]);
  }
}

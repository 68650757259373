import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ApiError } from '../../../resource/api.error.resource';
import { PurchaseType, PurchaseParams } from '../../../resource/purchaseType.resource';
import { Purchase } from '../../../resource/purchase.resource';
import { QueueLog } from '../../../resource/queue-log.resource';
import { FilteredItem } from '../../../resource/filteredItem.resource';
import { SoReach } from '../../../resource/so-reach.resource';
import { BroadcastPurchased } from '../../../resource/broadcast-purchased.resource';

import { PurchaseService } from '@service/purchase/purchase.service';
import { PurchaseAbatementService } from '@service/purchase-abatement/purchase-abatement.service';
import { PurchaseOfferProgramComponent } from './purchase-offer-program/purchase-offer-program.component';
import { EmailService } from '@service/email/email.service';
import { UserService } from '@service/user/user.service';
import { QueueLogService } from '@service/queue-log/queue-log.service';
import { PurchaseGrpService } from '@service/purchase-grp/purchase-grp.service';
import { CustomToastrService } from '@service/toastr/custom-toastr.service';
import { SignataireService } from '@service/signataire/signataire.service';
import { BroadcastPurchasedService } from '@service/broadcast-purchased/broadcast-purchased.service';
import { SoReachService } from '@service/so-reach/so-reach.service';

import { CustomValidators } from 'src/app/validator/custom-validators';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.scss']
})
export class PurchaseDetailComponent implements OnInit, OnDestroy {
  @ViewChild('purchaseOfferProgramComponent') purchaseOfferProgramComponent: PurchaseOfferProgramComponent;

  public soReachType: string;
  public stakeholderGroup: FormGroup;
  public purchase: Purchase;
  public enableConvention: boolean;

  private editing: boolean = false;
  private deleting: boolean = false;
  public saving: boolean = false;
  public savingPurchase: boolean = false;
  public loading: boolean = false;
  public isValid: boolean;
  public backToAvailability: boolean = false;

  // Queue logs progress variables
  public isCreating: boolean = false;
  public isEditing: boolean = false;
  public isDeleting: boolean = false;
  public queueCreatingCount: number;
  public queueProgressCount: number;
  private queueNeedToRefreshPage: boolean = false;

  private componentDestroyed$: Subject<any> = new Subject();
  private savePurchaseForCompare: Purchase = null;
  private refSavePurchase: Array<string> = [];

  private purchasedBroadcastList: BroadcastPurchased[];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private purchaseService: PurchaseService,
    private purchaseAbatementService: PurchaseAbatementService,
    private emailService: EmailService,
    private userService: UserService,
    private queueLogService: QueueLogService,
    private purchaseGrpService: PurchaseGrpService,
    private customToastrService: CustomToastrService,
    private signataireService: SignataireService,
    private broadcastPurchasedService: BroadcastPurchasedService,
    private soReachService: SoReachService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.initForm();
    this.route.params.subscribe(
      (params: Params) => {
        let action = params['action'];

        if (action === 'edit') {
          this.editing = true;
          this.deleting = true;
          this.loading = true;
          this.isValid = true;
          this.purchaseService.get(params['id'])
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(purchase => {
              this.titleService.setTitle(`${purchase.name}`);
              this.getSoReachType();
              this.purchase = purchase;
              this.purchaseService.setPurchase(purchase);
              this.backToAvailability = true;
              this.setSignatory(purchase);
              // Check creating queue in progress
              this.queueLogService.queueLogSource$
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(queueLog => {
                  // this.loading = false;
                  if (queueLog.length === 0) {
                    if (this.queueNeedToRefreshPage) {
                      if (this.queueLogService.purchaseFrom === QueueLog.PURCHASE_PROPALE) {
                        if (this.isCreating) {
                          this.reloadPurchase(params);
                        }
                        if (this.isDeleting) {
                          this.reloadPurchase(params);
                        }
                      }
                    } else {
                      this.loading = false;
                    }
                    this.isCreating = false;
                    this.isEditing = false;
                    this.isDeleting = false;
                  } else {
                    this.queueCreatingCount = queueLog[0].totalCount;
                    this.queueProgressCount = queueLog[0].okCount;
                    if (this.queueProgressCount === this.queueCreatingCount) {
                      if (this.queueNeedToRefreshPage) {
                        if (this.queueLogService.purchaseFrom === QueueLog.PURCHASE_PROPALE) {
                          if (this.isCreating) {
                            this.reloadPurchase(params);
                          }
                          if (this.isDeleting) {
                            this.reloadPurchase(params);
                          }
                        }
                      } else {
                        this.loading = false;
                      }
                      this.isCreating = false;
                      this.isEditing = false;
                      this.isDeleting = false;
                    } else {
                      this.isDeleting = true;
                      this.isCreating = true;
                      this.queueNeedToRefreshPage = true;
                    }
                  }
                }, () => {
                  this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                });

              this.queueLogService.checkQueueProgressOnTimer(
                QueueLog.QUEUE_NAME_BROADCAST_PURCHASED,
                this.purchase.id,
                QueueLog.PURCHASE_PROPALE,
                false);
          }, (error) => {
            if (error.status === 404) {
              this.customToastrService.displayToastr('ERROR', 'Votre offre n\'existe pas');
            } else {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            }
          });
        } else {
          if (!this.purchase) {
            this.titleService.setTitle(`Dossiers - Creation`);
            this.purchase = new Purchase(Purchase.init());
          }
          this.purchaseService.setPurchase(this.purchase);
          this.getSoReachType();
          this.backToAvailability = true;
          this.isValid = false;
        }
      }
    );

    this.purchaseService.savePurchase$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((type: string) => {
        if (!this.refSavePurchase.includes(type)) {
          this.refSavePurchase.push(type);
        }
        if (!this.savePurchaseForCompare && this.refSavePurchase.length === 6) {

          this.savePurchaseForCompare = Object.assign({}, this.purchase);
        }
      });

    this.purchaseService.purchaseSource$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        purchase => {
          this.purchase = purchase;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      }
    );

    this.purchaseService.isValidSource$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        isValid => {
          this.isValid = isValid;
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
      }
    );

    this.signataireService.enableConvention$
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((enable) => {
        this.enableConvention = enable;
      });

    this.broadcastPurchasedService.editingSource$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (editing: boolean) => {
          this.isEditing = editing;
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue lors de la modification d\'une diffusion.');
        }
    );

    this.broadcastPurchasedService.deletingSource$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (deleting: boolean) => {
          this.isDeleting = deleting;
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue lors de la suppression d\'une diffusion.');
        }
    )
    // Get list of purchased broadcast
    this.broadcastPurchasedService.broadcastPurchasedList$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (broadcastPurchasedList: BroadcastPurchased[]) => {
          this.purchasedBroadcastList = broadcastPurchasedList;
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue lors de la récupération des diffusions.');
        }
      )
  }

  getSoReachType(): void {
    this.soReachService.getSoReach()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((soReachTypes: SoReach[]) => {
        soReachTypes.forEach(element => {
          if (this.purchase && this.purchase.soreachPurchase === Number(element.id)) {
            this.soReachType = element.name;
          }
        })
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue lors de la récupération du type SoReach.');
      });
  }

  /**
   * redirect to Availability and fill a service
   */
  redirectToAvailability(): void {
    this.purchaseService.setPurchaseParams(new PurchaseParams(
      this.purchase.id,
      this.purchase.name,
      this.purchase.commercial,
      this.purchase.advertiser,
      this.purchase.soreachPurchase,
      moment(this.purchase.startCommunicationPeriod).format('YYYY-MM-DD'),
      moment(this.purchase.endCommunicationPeriod).format('YYYY-MM-DD'),
      this.purchaseOfferProgramComponent.purchaseProgramList,
    ));

     if (this.purchase.soreachPurchase) {
      this.router.navigate(['/availabilities'], {queryParams: {view: 'soreach'} });
     } else {
      this.router.navigate(['/availabilities']);
    }
  }


  /**
   * Set to control mandatary name/funct
   *
   * @param purchase
   */
  setSignatory(purchase): void {
    this.stakeholderGroup.get('customer').get('advertiserSignatory').setValue(purchase.advertiserSignatoryName ?
        new FilteredItem({name: purchase.advertiserSignatoryName, id: 1}) : purchase.advertiserSignatoryName);
    this.stakeholderGroup.get('customer').get('advertiserFonction').setValue(purchase.advertiserSignatoryFunct ?
        new FilteredItem({name: purchase.advertiserSignatoryFunct, id: 1}) : purchase.advertiserSignatoryFunct);
    this.stakeholderGroup.get('customer').get('mandatarySignatory').setValue(purchase.mandatarySignatoryName ?
        new FilteredItem({name: purchase.mandatarySignatoryName, id: 1}) : purchase.mandatarySignatoryName);
    this.stakeholderGroup.get('customer').get('mandataryFonction').setValue(purchase.mandatarySignatoryFunct ?
        new FilteredItem({name: purchase.mandatarySignatoryFunct, id: 1}) : purchase.mandatarySignatoryFunct);
    this.stakeholderGroup.get('customer').get('subMandatarySignatory').setValue(purchase.subMandatarySignatoryName ?
        new FilteredItem({name: purchase.subMandatarySignatoryName, id: 1}) : purchase.subMandatarySignatoryName);
    this.stakeholderGroup.get('customer').get('subMandataryFonction').setValue(purchase.subMandatarySignatoryFunct ?
        new FilteredItem({name: purchase.subMandatarySignatoryFunct, id: 1}) : purchase.subMandatarySignatoryFunct);
    this.stakeholderGroup.get('mainInformation').get('signatoryFtp').setValue(purchase.signatoryFtpCode);
  }

  reloadPurchase(params) {
    this.purchaseService.get(params['id'])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(purchase => {
        this.titleService.setTitle(`${purchase.name}`);
        this.purchase = purchase;
        this.purchaseService.setPurchase(purchase);
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.unsubscribe();
  }

  public sendProposalByMail() {
    if (this.userService.getEmailFromLocalStorage() !== null) {
      this.emailService
        .create({
            id_entity: this.purchase.id,
            email_ut: this.userService.getEmailFromLocalStorage(),
            type: 'proposalTemplate'
        }).pipe(
        takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.customToastrService.displayToastr('SUCCESS', 'Propale envoyé avec succès');
        }, () => {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
        });
    } else {
      this.customToastrService.displayToastr('ERROR', 'La propale n\'a pas été envoyée!');
    }
  }

  public initForm(): void {
    this.stakeholderGroup = this.fb.group({
      mainInformation: this.fb.group({
        commercial: [null, [Validators.required, CustomValidators.isFilteredItem]],
        subCommercial: null,
        planningUser: null,
        signatoryFtp: null
      }),
      customer: this.fb.group({
        advertiser: [null, [CustomValidators.temporyFieldRequired]],
        advertiserSignatory: [null],
        advertiserFonction: [null],
        product: null,
        campaign: null,
        secodip: null,
        mandatary: null,
        mandatarySignatory: null,
        mandataryFonction: null,
        mandateCertificate: null,
        subMandatary: null,
        subMandatarySignatory: null,
        subMandataryFonction: null,
        startCommunicationPeriod: [null, [Validators.required]],
        endCommunicationPeriod: [null, [Validators.required]],
        target: null,
        customerBudget: null,
      })
    });
  }

  public savePurchase(downloadConvention?: boolean) {
    if (!this.checkStatus() || !this.checkIsCampaignLinkedToProduct()) {
      return;
    }

    this.saving = true;
    if (this.editing === false) {
      this.purchaseService
        .createPurchase(this.purchase)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          result => {
              this.saving = false;
              this.purchase.typeInBase = result.typeInBase;
              this.customToastrService.displayToastr('SUCCESS', 'Dossier créé avec succès');
              this.router.navigate(['/purchases/edit/' + result['id']]);
          },
          error => {
              this.saving = false;
              if (error && error.detail) {
                let apiError = new ApiError(error.detail);
                this.customToastrService.displayToastr('ERROR', apiError.message);
              } else {
                this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue');
              }
          }
        );
    } else {
        let purchaseAbatements = this.purchaseAbatementService.getPurchaseAbatements('F');
        if (purchaseAbatements) {
          let count = 0;

          this.purchaseAbatementService.save(this.purchase, purchaseAbatements, 'F')
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
              if (++count === 3) {
                this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'F')
                  .pipe(takeUntil(this.componentDestroyed$))
                  .subscribe(() => {
                    this.saving = false;
                  }, () => {
                    this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                  });
              }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }

        let purchaseAbatementsRegion = this.purchaseAbatementService.getPurchaseAbatements('FREG');
        if (purchaseAbatementsRegion) {
          let countRegion = 0;
          this.purchaseAbatementService.save(this.purchase, purchaseAbatementsRegion, 'FREG')
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
              if (++countRegion === 3) {
                this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'FREG')
                  .pipe(takeUntil(this.componentDestroyed$))
                  .subscribe(() => {
                    this.saving = false;
                  }, () => {
                    this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                  });
              }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }

        let purchaseAbatementsDomtom = this.purchaseAbatementService.getPurchaseAbatements('O');
        if (purchaseAbatementsDomtom) {
          let countDomtom = 0;
          this.purchaseAbatementService.save(this.purchase, purchaseAbatementsDomtom, 'O')
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
              if (++countDomtom === 3) {
                this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'O')
                  .pipe(takeUntil(this.componentDestroyed$))
                  .subscribe(() => {
                    this.saving = false;
                  }, () => {
                    this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                  });
                }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }

        let purchaseAbatementsThema = this.purchaseAbatementService.getPurchaseAbatements('W');
        if (purchaseAbatementsThema) {
          let countThema = 0;
          this.purchaseAbatementService.save(this.purchase, purchaseAbatementsThema, 'W')
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
              if (++countThema === 3) {
                this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'W')
                  .pipe(takeUntil(this.componentDestroyed$))
                  .subscribe(() => {
                    this.saving = false;
                  }, () => {
                    this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                  });
              }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }

        let purchaseAbatementsInter = this.purchaseAbatementService.getPurchaseAbatements('I');
        if (purchaseAbatementsInter) {
          let countInter = 0;
          this.purchaseAbatementService.save(this.purchase, purchaseAbatementsInter, 'I')
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
              if (++countInter === 3) {
                this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'I')
                  .pipe(takeUntil(this.componentDestroyed$))
                  .subscribe(() => {
                    this.saving = false;
                  }, () => {
                    this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                  });
             }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }

        let purchaseAbatementsF4 = this.purchaseAbatementService.getPurchaseAbatements('F4');
        if (purchaseAbatementsF4) {
          let countF4 = 0;
          this.purchaseAbatementService.save(this.purchase, purchaseAbatementsF4, 'F4')
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
              if (++countF4 === 3) {
                this.purchaseAbatementService.loadPurchaseAbatements(this.purchase, 'F4')
                  .pipe(takeUntil(this.componentDestroyed$))
                  .subscribe(() => {
                    this.saving = false;
                  }, () => {
                    this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
                  });
              }
            }, () => {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue.');
            });
        }

        this.save(downloadConvention);
    }
  }

  /**
   * Download echeancier purchase pdf
   *
   * @memberof PurchaseDetailComponent
   */
  public downloadEcheancier() {
    const advertiserName = this.purchase.advertiser ? this.purchase.advertiser.name : '';
    this.purchaseService.downloadEcheancierPdf(this.purchase.id, advertiserName)
      .subscribe(() => {
          this.customToastrService.displayToastr('SUCCESS', 'Echéancier généré avec succès');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue lors de la génération de l\'échéancier.');
      })
  }

  /**
   * Generate prod sheet
   */
   public generateProdSheet(): void {
    this.purchaseService.exportXlsxProdSheet(this.purchase.id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
          this.customToastrService.displayToastr('SUCCESS', 'Feuille de prod généré avec succès');
      }, () => {
        this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue lors de la génération de la feuille de prod');
      })
  }

  /**
   * Download convention purchase word document
   *
   * @memberof PurchaseDetailComponent
   */
  public downloadConvention() {
    this.saving = true;
    this.savePurchase(true);
  }

  private downloadConventionAfterSave(): void {
    this.saving = true;
    this.savingPurchase = true;
    const advertiserName = this.purchase.advertiser ? this.purchase.advertiser.name : '';

    this.purchaseService.downloadConventionWord(this.purchase.id, advertiserName)
      .subscribe(() => {
        this.customToastrService.displayToastr('SUCCESS', 'Convention générée avec succès.');
        this.saving = false;
        this.savingPurchase = false;
      }, (error) => {
        this.saving = false;
        if (error && error.detail) {
          let apiError = new ApiError(error.detail);
          this.customToastrService.displayToastr('ERROR', apiError.message);
        } else {
          this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue lors de la génération de la convention.');
        }
      })
  }

  private save(downloadConvention?: boolean) {
    this.savingPurchase = true;
    if (downloadConvention && (JSON.stringify(this.savePurchaseForCompare) === JSON.stringify(this.purchase))) {
      this.downloadConventionAfterSave();
    } else {
      return this.purchaseService
        .editPurchase(this.purchase)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(result => {
            this.saving = false;
            this.savingPurchase = false;
            this.purchase.typeInBase = result.typeInBase;
            this.purchase.averageDurationRate = result.averageDurationRate;
            this.customToastrService.displayToastr('SUCCESS', 'Dossier édité avec succès');
            if ((this.purchase.typeInBase.id === PurchaseType.PURCHASE) && (!this.purchase.fsaId)) {
              this.purchase.fsaId = result.fsaId;
            }
            // Refresh GRP / coverage / repeate
            this.purchaseGrpService.updatePurchaseGrp(this.purchase.id);
            // If user click on download convention
            if (downloadConvention) {
              this.downloadConventionAfterSave();
            }
            this.savePurchaseForCompare = Object.assign({}, this.purchase);
          },
          error => {
            this.saving = false;
            this.savingPurchase = false;
            if (error && error.detail) {
              let apiError = new ApiError(error.detail);
              this.customToastrService.displayToastr('ERROR', apiError.message);
            } else {
              this.customToastrService.displayToastr('ERROR', 'Une erreur est survenue');
            }
          });
    }
  }

  /**
   * Check if purchase campaign is linked to purchase product
   *
   * @private
   */
  private checkIsCampaignLinkedToProduct(): boolean {
    if (this.purchase && this.purchase.product && this.purchase.product.id && this.purchase.campaign &&
        this.purchase.campaign.product && this.purchase.campaign.product.id &&
        this.purchase.product.id !== this.purchase.campaign.product.id) {
      this.customToastrService.displayToastr('ERROR', 'La campagne ne correspond pas au produit');
      return false;
    } else {
      return true;
    }
  }

  private cancel() {
    this.router.navigate(['/purchases/list']);
  }

  /**
   * Check if status can change
   *
   * @private
   * @returns {boolean}
   *
   * @memberOf PurchaseDetailComponent
   */
  private checkStatus(): boolean {
    let isValid = false;

    if (this.purchase.type && this.purchase.typeInBase) {
      const newStatus: number = +this.purchase.type.id;
      const oldStatus: number = +this.purchase.typeInBase.id;
      if (+newStatus === +PurchaseType.PURCHASE) {
        if (!this.checkIfPurchaseIsCompleted()) {
          this.customToastrService.displayToastr(
            'ERROR', 'Vous ne pouvez pas changer vers ce statut de dossier car le dossier est incomplet.');
        } else {
          isValid = true;
        }
      } else if (!this.checkStatusWorkflow(newStatus, oldStatus)) {
        this.customToastrService.displayToastr('ERROR', 'Vous ne pouvez pas changer vers ce statut de dossier.');
      } else if (this.purchase.type && this.purchase.type.id === PurchaseType.CANCELED && this.containsInvoicedBroadcasts()) {
        this.customToastrService.displayToastr('ERROR', 'Ce dossier ne peut pas être annulé car certaines diffusions sont déjà facturées');
      } else {
        isValid = true;
      }
    } else {
      isValid = true;
    }
    return isValid;
  }

  /**
   * Check if all information needed are present
   *
   * @private
   * @returns {boolean}
   *
   * @memberOf PurchaseDetailComponent
   */
  private checkIfPurchaseIsCompleted(): boolean {
      let isCompleted = false;
      if (this.purchase.commercial && this.purchase.advertiser && this.purchase.product) {
        isCompleted = true;
      }
      return isCompleted;
  }

  /**
   * Check Workflow of status when it was purchased
   *
   * @private
   * @param {number} newStatus
   * @param {number} oldStatus
   * @returns {boolean}
   *
   * @memberOf PurchaseDetailComponent
   */
  private checkStatusWorkflow(newStatus: number, oldStatus: number): boolean {
    let allowed = true;
    const notAllowedTypes: Array<number> = [
      +PurchaseType.OPTION,
      +PurchaseType.SENT,
      +PurchaseType.ALERT,
      +PurchaseType.IN_PROGRESS
    ];
    const endpoints: Array<number> = [
      +PurchaseType.PURCHASE,
      +PurchaseType.CANCELED,
      +PurchaseType.REFUSED,
      +PurchaseType.EXPIRED
    ];
    if (endpoints.some((item) => item === +oldStatus)) {
      allowed = !notAllowedTypes.some((item) => item === +newStatus);
    }
    return allowed;
  }

  /**
   * Check if purchase contains invoiced broadcasts
   */
  private containsInvoicedBroadcasts(): boolean {
    let hasInvoicedBroadcasts = false;

    if (this.purchasedBroadcastList && this.purchasedBroadcastList.length) {
      hasInvoicedBroadcasts = this.purchasedBroadcastList.some((broadcastPurchased: BroadcastPurchased) =>
        broadcastPurchased.status && broadcastPurchased.status === BroadcastPurchased.INVOICED)
    }

    return hasInvoicedBroadcasts;
  }
}
